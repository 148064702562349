import React from "react"
import { Helmet } from "react-helmet"

const Header = () => {
  return (
    <Helmet
      htmlAttributes={{ class: "text-xs md:text-sm antialiased" }}
      bodyAttributes={{ class: "px-4 lg:px-0" }}
    >
      <script
        defer
        src="https://static.cloudflareinsights.com/beacon.min.js"
        data-cf-beacon='{"token": "1d1b52ba50b1416287372f43afb5e963"}'
      ></script>
    </Helmet>
  )
}

export default Header
