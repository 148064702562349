import React from "react"

const Footer = () => {
  return (
    <footer
      className="border-t bg-gray-100 py-4 text-center text-sm"
      itemscope
      itemtype="https://schema.org/WPFooter"
    >
      <p>
        &copy; {new Date().getFullYear()} {process.env.SITE_NAME} - Site by{" "}
        <a
          className="text-link"
          href="https://www.tobeontheweb.nl"
          itemProp="url"
        >
          To Be On The Web
        </a>
      </p>
    </footer>
  )
}

export default Footer
