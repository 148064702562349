import React, { useState, useEffect } from "react"
import { BsFillArrowUpCircleFill } from "react-icons/bs"

const Scroll = ({ showBelow }) => {

  const [show, setShow] = useState(showBelow ? false : true)

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  })

  return (
    <div>
      {show && (
        <BsFillArrowUpCircleFill
          fontSize={50}
          onClick={handleClick}
          className="hidden lg:block toTop"
          aria-label="Scrolll naar boven"
          component="span"
        ></BsFillArrowUpCircleFill>
      )}
    </div>
  )
}
export default Scroll
