import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Doelen = () => {
  return (
    <div className="section -mx-4 bg-skin-section-even px-6 lg:mx-0 lg:px-0">
      <div className="container prose prose-slate mx-auto max-w-5xl">
        <h2 id="doelen" className="text-center">
          Doelen
        </h2>
        <div className="grid gap-8 lg:grid-cols-2">
          <div className="">
            <h3>Visie</h3>
            <p>
              Ons zwembad is een onmisbare gemeenschapsvoorziening die sport,
              ontmoeting, ontspanning en buiten bewegen biedt voor alle kleine
              en grote mensen in onze gemeente.
            </p>
            <h3>Maatschappelijke opdracht / doelen</h3>
            <p>
              De zwembadraad geeft uitvoering aan de taakstellende bezuiniging
              van de gemeenteraad (2012) om de exploitatie van het zwembad met
              inzet van de samenleving omlaag te brengen.
            </p>
            <div>
              <StaticImage
                className=""
                src="../assets/images/driehoek.jpg"
                alt="Driehoek zwembadraad blijven spetteren"
                placeholder="blurred"
                width={300}
              />
            </div>
          </div>
          <div>
            <p>
              De zwembadraad voert, binnen de driehoek, de maatschappelijke
              opdracht uit door het realiseren van deze doelen.
            </p>
            <ol>
              <li>
                Afhankelijk van de weersomstandigheden bewegen minimaal 30.000
                bezoekers per jaar.
              </li>
              <li>
                Ontmoetingsplaats voor ouderen, jonge kinderen en gezinnen.
              </li>
              <li>Minimaal 19 weken per jaar geopend.</li>
              <li>
                Betaalbare voorziening voor gezinnen met minder inkomen
                (richtlijn inflatiecorrectie).
              </li>
              <li>Tussen de 4 – 6 dagen zwemles aanbieden.</li>
              <li>
                Minimaal 100 zwemlesklanten die betaalbare zwemlessen volgen.
              </li>
              <li>Locatie voor zinvolle en leuke vrijwilligersfuncties.</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Doelen
