import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Tiles = () => {
  return (
    <div className="section -mx-4 bg-skin-section-odd lg:mx-0">
      <div className="container mx-auto grid max-w-5xl grid-cols-1 place-items-center gap-8 md:grid-cols-4">
        <a
          href="https://www.despettertholen.nl/vrijwilligers/"
          target="_blank"
          rel="noreferrer"
          className="relative flex h-40 justify-center"
        >
          <StaticImage
            className=""
            src="../assets/images/groenploeg.jpg"
            alt="Groenploeg aan het werk op zwembad de spetter"
            placeholder="blurred"
            width={450}
          />
          <div className="text-shadow absolute flex h-full w-full items-center justify-center bg-gray-600/30 text-2xl text-white">
            Help mee in <br/> de Groenploeg
          </div>
        </a>
        <a
          href="https://www.despettertholen.nl/vrijwilligers/"
          target="_blank"
          rel="noreferrer"
          className="relative flex h-40 justify-center"
        >
          <StaticImage
            src="../assets/images/toezichthouder.jpg"
            alt="Toezichthouder"
            placeholder="blurred"
            width={450}
          />
          <div className="text-shadow absolute flex h-full w-full items-center justify-center bg-gray-600/30 text-2xl text-white">
            Word jij vrijwillig <br/> toezichthouder?
          </div>
        </a>
        <a
          className="relative flex h-40 justify-center"
          href="https://www.despettertholen.nl/"
          target="_blank"
          rel="noreferrer"
          title="Website zwembad de Spetter"
        >
          <StaticImage
            src="../assets/images/zwembaddespetter.jpg"
            alt="Foto Zwembad de Spetter"
            placeholder="blurred"
            width={450}
          />
          <div className="text-shadow absolute flex h-full w-full items-center justify-center bg-gray-600/30 text-2xl text-white">
            Zwembad <br /> De Spetter
          </div>
        </a>
        <a
          href="https://www.despettertholen.nl/vrijwilligers/"
          target="_blank"
          rel="noreferrer"
          className="relative flex h-40 justify-center"
        >
          <StaticImage
            src="../assets/images/doe-mee.jpg"
            alt="Doe mee als vrijwilliger"
            placeholder="blurred"
            width={450}
          />
          <div className="text-shadow absolute flex h-full w-full items-center justify-center bg-gray-600/30 text-2xl text-white">
            Doe <br />
            mee!
          </div>
        </a>
      </div>
    </div>
  )
}

export default Tiles
