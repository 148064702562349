import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { StaticImage } from "gatsby-plugin-image"

const Sponsoren = () => {
  const settings = {
    autoplay: true,
    lazyLoad: true,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    //adaptiveHeight: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="section -mx-4 bg-skin-section-odd lg:mx-0">
      <div className="container prose prose-slate mx-auto max-w-5xl">
        <h2 className="text-center">Onze Sponsoren</h2>
        <Slider {...settings} className="">
          <div className="text-center">
            <a
              href="http://jkbouwconsult.nl/"
              title="Naar de website van JK Bouwconsult"
            >
              <StaticImage
                className=""
                src="../assets/images/sponsoren/jk-bouwconsult-logo.gif"
                alt="Logo JK Bouwconsult"
                height={95}
                //objectPosition="center center"
                //transformOptions={{ fit: "contain" }}
              />
            </a>
          </div>
          <div className="px-3 text-center">
            <a
              href="https://www.facebook.com/CarbidStichtingTholen/"
              title="Naar de facebook pagina van Carbid Stichting Tholen"
            >
              <StaticImage
                className=""
                src="../assets/images/sponsoren/carbid-stichting-tholen.jpg"
                alt="Logo Carbid Stichting Tholen"
                height={95}
              />
            </a>
          </div>
          <div className="px-3 text-center">
            <a
              href="https://tholen.lions.nl/"
              title="Naar de website van Lions Internation"
            >
              <StaticImage
                className=""
                src="../assets/images/sponsoren/logo-lions.png"
                alt="Logo Lions Internation"
                height={95}
              />
            </a>
          </div>
          <div className="px-3 text-center">
            <a
              href="https://www.naturalpetshop.nl"
              title="Naar de website van Natural Pet Shop"
            >
              <StaticImage
                className=""
                src="../assets/images/sponsoren/logo-naturalpetshop-small.png"
                alt="Logo Natural Pet Shop"
                height={95}
              />
            </a>
          </div>
          <div className="px-3 text-center">
            <a
              href="http://www.edufiles.nl/"
              title="Naar de website van Edufiles"
            >
              <StaticImage
                className=""
                src="../assets/images/sponsoren/logo-edufiles.png"
                alt="Logo Edufiles"
                height={95}
              />
            </a>
          </div>
          <div className="px-3 text-center">
            <a
              href="https://www.ah.nl/winkel/4067"
              title="Naar de website van Albert Heijn"
            >
              <StaticImage
                className=""
                src="../assets/images/sponsoren/logo-ah.jpg"
                alt="Logo Albert Heijn"
                height={95}
              />
            </a>
          </div>
          <div className="px-3 text-center">
            <a
              href="http://www.coremans.nl/"
              title="Naar de website van Coremans"
            >
              <StaticImage
                className=""
                src="../assets/images/sponsoren/coremans.gif"
                alt="Logo Coremans"
                height={95}
              />
            </a>
          </div>
          <div className="px-3 text-center">
            <a
              href="http://www.heynenbv.nl/"
              title="Naar de website van Machinefabriek Heynen"
            >
              <StaticImage
                className=""
                src="../assets/images/sponsoren/logo-heynen.png"
                alt="Logo Machinefabriek Heynen"
                height={95}
              />
            </a>
          </div>
          <div className="px-3 text-center">
            <a
              href="http://www.autoschadebuijs.nl/"
              title="Naar de website van Autoschade Buijs"
            >
              <StaticImage
                className=""
                src="../assets/images/sponsoren/ab_logo.jpg"
                alt="Logo Autoschade Buijs"
                height={95}
              />
            </a>
          </div>
          <div className="px-3 text-center">
            <a href="http://www.id4u.nl/" title="Naar de website van Id4u">
              <StaticImage
                className=""
                src="../assets/images/sponsoren/logo_Id4U.jpg"
                alt="Logo Id4u"
                height={95}
              />
            </a>
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default Sponsoren
