import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const WieZijnWij = () => {
  return (
    <div
      id="wie-zijn-wij"
      className="section -mx-4 bg-skin-section-even px-6 lg:mx-0 lg:px-0"
    >
      <div className="container prose prose-slate mx-auto max-w-5xl">
        <h2 className="text-center">Wie zijn wij</h2>
        <div className="grid gap-10 lg:grid-cols-2">
          <div>
            <p>
              Zwembadraad BlijvenSpetteren bestaat uit 7 leden die zich inzetten
              voor beheer en behoud van zwembad de Spetter voor nu en
              toekomstige generaties. Wil je aansluiten bij onze zwembadraad
              mail dan{" "}
              <a href="mailto:werkgroeptz@gmail.com">werkgroeptz@gmail.com</a>
            </p>
            <div>
              <StaticImage
                className=""
                src="../assets/images/groepsfoto.jpg"
                alt="Groepsfoto zwembadraad blijven spetteren"
                placeholder="blurred"
                width={300}
              />
            </div>
          </div>
          <div>
            <h3>Diverse taken zwembadraad</h3>
            <ol>
              <li>
                Onderhoud beheer- en bedrijfsplan 2018-2028{" "}
                <a href="/documenten/20171017-Def.versie college_beheer- en bedrijfsplan_Spetter_2018-2028.pdf">
                  (PDF)
                </a>
              </li>
              <li>Meesturen op resultaat</li>
              <li>Meesturen op het behalen van de doelen</li>
              <li>
                Maandelijks driehoeksoverleg met gemeente en zwembadmanager
              </li>
              <li>Circa 8 x jaar zwembadraad</li>
              <li>Jaarrapport naar college &amp; raad</li>
              <li>Organisatie NLdoet</li>
              <li>vrijwillig bevoegd toezichthouden (VBT)</li>
              <li>
                Spreekbuisfunctie voor burgers en bezoekers binnen de driehoek
              </li>
              <li>Relatiebeheer sponsoren</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WieZijnWij
