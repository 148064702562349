import React from "react"
import { Header, Navbar, Footer, Scroll } from "."
import { StaticImage } from "gatsby-plugin-image"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { BsYoutube, BsFacebook, BsFillEnvelopeFill } from "react-icons/bs"

const Layout = ({ children }) => {
  return (
    <>
      <GatsbySeo
        titleTemplate={`%s | ${process.env.SITE_NAME}`}
        language="nl"
      />
      <Header />
      <div className="theme-despetter min-h-screen md:flex md:flex-col">
        <div className="w-full bg-gray-200">
          <div className="py-3 text-center text-xs lg:text-sm">
            Betrokken burgers hebben zich verenigd in zwembadraad
            BlijvenSpetteren. Zij streven behoud en renovatie van zwembad de
            Spetter na.
          </div>
        </div>
        <header
          className="container mx-auto flex max-w-5xl place-items-center bg-skin-section-odd py-8"
          itemscope
          itemtype="https://schema.org/WPHeader"
        >
          <h1 className="mr-8">
            <Link to="/" title={`homepagina ${process.env.SITE_NAME}`}>
              <StaticImage
                src="../assets/images/logo_blijvenspetteren_small.png"
                alt={`Logo ${process.env.SITE_NAME}`}
                placeholder="blurred"
                quality="100"
                width={250}
                className=""
              />
            </Link>
          </h1>
          <Navbar />
        </header>
        <div className="md:flex md:flex-1 md:flex-col">
          <main className="md:flex-1">{children}</main>
          <Scroll showBelow={250} />
        </div>
        <div className="mt-8 border-t border-gray-300">
          <div className="container mx-auto grid max-w-5xl lg:grid-cols-3">
            <div className="pl-8 pb-8">
              <h2 className="py-8 uppercase">Navigatie</h2>
              <div>
                <ul>
                  <li>
                    <Link to="/archief-2011-2017" className="hover:underline">
                      Archief 2011–2017
                    </Link>
                  </li>
                  <li className="mt-2">
                    <AnchorLink to="/#wie-zijn-wij" className="hover:underline">
                      Wie zijn wij
                    </AnchorLink>
                  </li>
                  <li className="mt-2">
                    <AnchorLink to="/#doelen" className="hover:underline">
                      Doelen
                    </AnchorLink>
                  </li>
                  <li className="mt-2">
                    <Link to="/video-gallery" className="hover:underline">
                      <BsYoutube fontSize={24} />
                    </Link>
                  </li>
                  <li className="mt-2">
                    <a
                      href="https://www.facebook.com/blijvenspetteren/"
                      className="hover:underline"
                      target="_blank"
                      rel="noreferrer"
                      title="Facebook pagina Zwembadraad Blijven spetteren"
                      itemProp="url"
                    >
                      <BsFacebook fontSize={24} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="border-l pl-8 pb-8">
              <h2 className="py-8 uppercase">Contact</h2>
              <div>
                <p>Info of vragen</p>{" "}
                <a
                  href="mailto:werkgroeptz@gmail.com"
                  className="block text-link"
                >
                  werkgroeptz@gmail.com
                </a>{" "}
                <br />
                Postadres:
                <br />
                BlijvenSpetteren
                <br /> p/a Burg. Waghtoplein 4<br />
                4691 JH Tholen
              </div>
            </div>
            <div className="border-l pl-8 pb-8">
              <h2 className="py-8 uppercase">Volg Ons</h2>
              <div>
                <div className="flex">
                  <a
                    href="https://www.facebook.com/blijvenspetteren/"
                    className="mr-2  hover:underline"
                    target="_blank"
                    rel="noreferrer"
                    title="Facebook pagina Zwembadraad Blijven spetteren"
                    itemProp="url"
                  >
                    <BsFacebook fontSize={24} />
                  </a>
                  <a href="mailto:werkgroeptz@gmail.com">
                    <BsFillEnvelopeFill fontSize={24} />
                  </a>
                </div>
                <a
                  href="http://www.tholen.nl/"
                  target="_blank"
                  rel="noreferrer"
                  title="Naar de website van de gemeente Tholen"
                  itemProp="url"
                >
                  <StaticImage
                    src="../assets/images/Logo-gemeente-Tholen.jpg"
                    alt="logo gemeente Tholen"
                    placeholder="blurred"
                    quality="100"
                    width={250}
                    className=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Layout
