import React, { useState } from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { FiAlignJustify, FiX } from "react-icons/fi"
import { BsYoutube, BsFacebook } from "react-icons/bs"

const Navbar = () => {
  const links = [
    {
      name: "Archief 2011-2017",
      target: "/archief-2011-2017",
      title: "Archief 2011-2017 Zwembadraad Blijven Spetteren",
    },
    {
      name: "Wie zijn wij",
      target: "/#wie-zijn-wij",
      title: "Wie zijn Zwembadraad Blijven Spetteren",
    },
    {
      name: "Doelen",
      target: "/#doelen",
      title: "Doelen Zwembadraad Blijven Spetteren",
    },
    {
      name: "Documenten",
      target: "/documenten",
      title: "Documenten Zwembadraad Blijven Spetteren",
    },
  ]

  const liClassname = "py-4 md:py-0 border-t md:border-0 border-gray-400"
  const linkClassname = "md:hover:text-spetter "

  const [show, setShow] = useState(false)

  return (
    <nav className="container mx-auto max-w-5xl font-header text-sm uppercase">
      <button
        className="mobile-nav-toggle"
        aria-controls="primary-navigation"
        aria-expanded={show ? "true" : "false"}
        onClick={() => setShow(!show)}
      >
        <FiAlignJustify
          fontSize={28}
          aria-hidden="true"
          className={
            "cursor-pointer text-black md:hidden " + (show ? "hidden" : "block")
          }
        />
        <FiX
          fontSize={28}
          aria-hidden="true"
          className={"cursor-pointer md:hidden " + (show ? "block" : "hidden")}
        />
        <span className="sr-only">Menu</span>
      </button>
      <ul
        id="primary-navigation"
        className={
          "list-none flex-col md:flex md:h-8 md:flex-row md:justify-between " +
          (show ? "primary-navigation-open flex" : "hidden")
        }
      >
        {links.map((link, index) => (
          <li key={index} className={liClassname}>
            <AnchorLink
              className={linkClassname}
              activeClassName=""
              to={link.target}
              title={link.title}
            >
              {link.name}
            </AnchorLink>
          </li>
        ))}
        <li className={liClassname}>
          <Link
            to="/video-gallery"
            className={linkClassname}
            title="Video gallerie Zwembadraad Blijven spetteren"
          >
            <BsYoutube fontSize={24} />
          </Link>
        </li>
        <li className={liClassname}>
          <a
            href="https://www.facebook.com/blijvenspetteren/"
            className={linkClassname}
            target="_blank"
            rel="noreferrer"
            title="Facebook pagina Zwembadraad Blijven spetteren"
            itemProp="url"
          >
            <BsFacebook fontSize={24} />
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
