import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { StaticImage } from "gatsby-plugin-image"

const Hero = () => {
  const settings = {
    autoplay: true,
    arrows: true,
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  }
  return (
    <div className="-mx-4 lg:mx-0">
      <Slider {...settings} className="">
        <div className="relative flex justify-center">
          <StaticImage
            className="h-64 w-full md:h-auto"
            src="../assets/images/hero-groenploeg.jpg"
            alt="Groenploeg"
            height={450}
            //transformOptions={{ fit: "cover" }}
          />
          <div className="text-shadow absolute inset-0 flex h-full w-full justify-center bg-gray-600/40 px-4 pt-12 text-white lg:text-3xl">
            <div>
              <p>Doe je mee als vrijwilliger groenploeg of toezichthouden?</p>
              <a
                href="https://www.despettertholen.nl/vrijwilligers/"
                target="_blank"
                rel="noreferrer"
                title="Meld je aan als vrijwilliger bij Zwembad De Spetter Tholen"
                className="m-auto mt-8 block w-40 rounded-md bg-blue-500 p-2 text-center text-base md:ml-0 lg:p-4"
                role="button"
                aria-pressed="false"
              >
                Aanmelden
              </a>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  )
}

export default Hero
