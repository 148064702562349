import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const getData = graphql`
  query FacebookQuery {
    allFacebook(sort: { fields: created_time, order: DESC }) {
      nodes {
        attachments {
          data {
            description
            media {
              image {
                src
              }
            }
          }
        }
        id
        created_time
        localFile {
          childImageSharp {
            gatsbyImageData(height:400)
          }
        }
      }
    }
  }
`

const Facebook = () => {
  const data = useStaticQuery(getData)
  return (
    <div className="section -mx-4 bg-skin-section-odd px-6 lg:mx-0 lg:px-0">
      <div className="container prose prose-slate mx-auto max-w-5xl">
        <h2 className="text-center">Facebook nieuwsberichten</h2>
        <div className="grid gap-10 lg:grid-cols-2">
          {data.allFacebook.nodes.map((fb, index) => {
            //console.log(fb.created_time)
            const image = getImage(fb.localFile.childImageSharp.gatsbyImageData)
            const imageSrc = (
              <GatsbyImage image={image} alt="Foto facebook bericht" />
            )
            return (
              <div key={index} className="grid auto-rows-auto">
                {imageSrc}
                <div
                  className="my-4"
                  dangerouslySetInnerHTML={{
                    __html: fb.attachments.data[0].description,
                  }}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Facebook
